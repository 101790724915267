<template>
  <div class="row">
    <div class="col-12 col-xl-6">
      <div class="dashbox">
        <div class="dashbox-title"><h2>Game tele</h2></div>
        <div class="text-center">
          <p>
            Ang telegram game ay isang game at direktang ibabalik ang results
            mula sa Telegram <br />
            Mayroong dalawang main games kasama ang dice at slot games Halagang
            matatanggap kung nanalo <br />
            = bet amount X1.91 sa even/ odd - big/ small games <br />
            = bet amount X5 sa mga guessing game <br />
            = bet amount X10, X30 sa mga slot games <br />
            - Lalo na may pagkakataon kang makatanggap ng malaking jackpot
            araw-araw
          </p>
          <p v-if="!linkInfo || linkInfo.link_id === 'not-link'">
            HINDI NAKA-LINK ANG IYONG TELEGRAM ACCOUNT
          </p>
          <p v-else>Naka-link ka sa isang bot: {{ linkInfo.bot_name }}</p>
          <a :href="computedLink" class="lk-tele" target="_blank">
            {{
               "MAGLARO NGAYON"
            }}
          </a>
          <!-- <a
            :href="linkInfo ? linkInfo.link_deep_link : '#'"
            class="lk-tele"
            target="_blank"
          >
            {{
              !linkInfo || linkInfo.link_id === "not-link"
                ? "I-LINK NGAYON"
                : "MAGLARO NGAYON"
            }}
          </a> -->
        </div>
      </div>
    </div>
    <div class="col-12 col-xl-6">
      <div class="dashbox">
        <div class="dashbox-title">
          <h2>Mga instruction video</h2>
        </div>
        <div class="video-game">
          <iframe
            width="100%"
            height="400"
            src="https://www.youtube.com/embed/2lkbDUW1zSk?si=yakThFK4zUzL0t1B"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchLinkInfo } from "@/api/link-tele";

export default {
  data() {
    return {
      linkInfo: null,
      error: null,
      username: "your_username", // Thay thế bằng giá trị thực tế của username
      isLoggedIn: false, // Thêm biến để theo dõi trạng thái đăng nhập
    };
  },
  computed: {
    telegramLink() {
      return `https://t.me/lucky_gcash_bot?start=ref=${this.username}`;
    },
    computedLink() {
      if (
        this.isLoggedIn &&
        this.linkInfo &&
        this.linkInfo.link_id !== "not-link"
      ) {
        return this.linkInfo.link_deep_link;
      } else {
        return this.telegramLink;
      }
    },
  },
  methods: {
    async getLinkInfo() {
      try {
        const data = await fetchLinkInfo();
        if (data && data.data) {
          this.linkInfo = data.data;
        }
      } catch (error) {
        console.error("Error fetching link info:", error);
        this.error = error.response ? error.response.data : error.message;
      }
    },
    checkLoginStatus() {
      // Kiểm tra trạng thái đăng nhập của người dùng
      const token = localStorage.getItem("access_token");
      this.isLoggedIn = !!token;
    },
  },
  mounted() {
    this.checkLoginStatus();
    if (this.isLoggedIn) {
      this.getLinkInfo();
    }
  },
};
</script>

<style>
</style>

<template>
  <div class="row">
    <div class="col-12 col-xl-6">
      <div class="dashbox">
        <div
          class="dashbox-title d-flex justify-content-around align-items-center"
        >
          <h2>Hulaan ang number</h2>
          <a class="scroll-video link-color" href="#video-hulaan-ang-number"
            >Tutorial</a
          >
        </div>
        <div class="game-describe" style="text-align: justify">
          Ang task mo sa number-guessing game ay hulaan ang huling number ng
          Reference Number (0,1,2,3,4,5,6,7,8,9)<br />
          Paano laruin: Kapag nahulaan mo ang huling digit ng Reference Number
          ay 1, magtransfer ng pera na may 1 decimal na number halimbawa 10.1
          PHP, 20.1 PHP<br />
          Katulad din sa mga natitirang number, kapag nahulaan mo na ang huling
          digit ng Reference Number ay 7, magtransfer ng pera na may decimal na
          number na 7. Halimbawa: 10.7 PHP; 20.7 P<br />
          Kung ang iyong hula ay 0, tumaya ka na may halagang 10 PHP, 20 PHP at
          walang mga decimal pagkatapos nito.
        </div>
        <div class="p-0">
          <table class="game-table">
            <thead>
              <tr>
                <th>GCash Wallet</th>
                <th>Huling number</th>
                <th>Ratio</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in data" :key="index">
                <td class="td-fl">
                  <span>{{ item.nickname }}</span>

                  <button
                    id="'copyButton_' + index"
                    class="btn"
                    @click="copyRewardContent(item.nickname)"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-clipboard"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1z"
                      />
                      <path
                        d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z"
                      />
                    </svg>
                  </button>
                </td>
                <td>
                  <code
                    v-for="(number, idx) in item.lastNumbers.split('')"
                    :key="idx"
                    >{{ number }}</code
                  >
                </td>
                <td>{{ item.rate }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="game-describe text-center">
          <span class="game-describe text-center attention-text">
            Ang resulta ay batay sa huling numero ng Reference Number mula sa
            GCash wallet
          </span>
          <br />
          - Minimum 20 ₱/bawat money transfer<br />
          - Maximum na 500 ₱/bawat money transfer <br />Tandaan: Kung ililipat
          mo ang minimum o maximum na halaga na hindi alinsunod sa game
          regulations. Ang system ay hindi magrerefund ng pera.
        </div>
      </div>
    </div>
    <div class="col-12 col-xl-6">
      <div class="dashbox" id="video-hulaan-ang-number">
        <div class="dashbox-title">
          <h2>Mga instruction video</h2>
        </div>
        <div class="video-game">
          <iframe
            width="100%"
            height="400"
            src="https://www.youtube.com/embed/kD-Qd1UPJyE?si=AZZEkQI3VEcIZs0-"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from "@/js/eventBus";

export default {
  name: "GameDoanso",
  data() {
    return {
      isLoggedIn: false,
      data: [
        { nickname: "09931338511", lastNumbers: "0", rate: "x7" },
        { nickname: "09931338511", lastNumbers: "1", rate: "x8" },
        { nickname: "09931338511", lastNumbers: "2", rate: "x8" },
        { nickname: "09931338511", lastNumbers: "3", rate: "x8" },
        { nickname: "09931338511", lastNumbers: "4", rate: "x8" },
        { nickname: "09931338511", lastNumbers: "5", rate: "x6" },
        { nickname: "09931338511", lastNumbers: "6", rate: "x8" },
        { nickname: "09931338511", lastNumbers: "7", rate: "x8" },
        { nickname: "09931338511", lastNumbers: "8", rate: "x8" },
        { nickname: "09931338511", lastNumbers: "9", rate: "x8" },
      ],
    };
  },
  created() {
    this.checkLoginStatus();
  },
  methods: {
    checkLoginStatus() {
      const user = JSON.parse(localStorage.getItem("user"));
      this.isLoggedIn = !!user;
    },
    copyRewardContent(content) {
      // Sử dụng một khóa chung cho tất cả các nút sao chép
      const lastCopyEventTime = localStorage.getItem("lastCopyEventTime");
      const now = new Date().getTime();
      const thirtyDaysInMilliseconds = 30 * 24 * 60 * 60 * 1000;

      // Kiểm tra nếu sự kiện chưa xảy ra hoặc đã hơn 30 ngày
      if (
        !lastCopyEventTime ||
        now - lastCopyEventTime > thirtyDaysInMilliseconds
      ) {
        navigator.clipboard.writeText(content).then(() => {
          EventBus.emit("showCopyNotification");

          // Gửi sự kiện Facebook Pixel
          if (typeof fbq === "function") {
            window.fbq("track", "CopyLucky", {
              walletNumber: content,
            });
          }

          // Lưu dấu thời gian kích hoạt sự kiện
          localStorage.setItem("lastCopyEventTime", now.toString());
        });
      } else {
        // Nếu sự kiện đã được ghi nhận trong vòng 30 ngày, chỉ sao chép nội dung
        navigator.clipboard.writeText(content).then(() => {
          EventBus.emit("showCopyNotification");
        });
      }
    },
  },
};
</script>

<style>
</style>


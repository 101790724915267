// src/main.js

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import "@mdi/font/css/materialdesignicons.css";
import "bootstrap/dist/css/bootstrap.css";
import { createHead } from "@vueuse/head";

const app = createApp(App);

app.use(router);
app.use(vuetify);
app.use(createHead());

app.mount("#main");

<template>
  <div class="col-lg-6 mx-auto">
    <div class="dashbox p-5">
      <div class="dashbox-img">
        <a href="/">
          <img src="../../public/images/logo.png" alt="" width="200px" />
        </a>
      </div>

      <form @submit.prevent="login" class="text-center w-100 position-relative">
        <div class="form-group">
          <input
            type="text"
            id="username"
            v-model="username"
            class="form-control"
            placeholder="Enter account name"
          />
          <div v-if="errors.username" class="error-message">
            {{ errors.username }}
          </div>
        </div>
        <div class="form-group">
          <input
            type="password"
            id="password"
            v-model="password"
            class="form-control"
            placeholder="Enter password"
          />
          <div v-if="errors.password" class="error-message">
            {{ errors.password }}
          </div>
        </div>

        <!-- Captcha Image -->
        <div class="form-group form-captcha">
          <img :src="captchaImage" alt="Captcha Image" class="img-captcha" />
          <button type="button" @click="loadCaptcha">
            <i class="fa-solid fa-rotate" style="color: #ffffff"></i>
          </button>
        </div>
        <div class="form-group">
          <input
            type="text"
            id="captchaCode"
            v-model="captchaCode"
            class="form-control"
            placeholder="Enter captcha code"
          />
          <div v-if="errors.captchaCode" class="error-message">
            {{ errors.captchaCode }}
          </div>
        </div>

        <div>
          <button class="w-100 bg-gradient-button" type="submit">
            <span>Log-in</span>
          </button>
        </div>

        <div class="d-flex justify-content-around">
          <span>
            No account?
            <a class="link-color" href="/register">Register</a>
          </span>
          <span>
            <a class="link-color" href="https://t.me/luckygcash" target="_blank"
              >Forgot Password?</a
            >
          </span>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { loginUser } from "@/api/login";
import { getCaptchaId, getCaptchaImage } from "@/api/captcha";

import EventBus from "@/js/eventBus";
import { loginSchema } from "@/schemas/loginSchema";
import { useHead } from "@vueuse/head";

export default {
  name: "LoginForm",
  setup() {
    useHead({
      title: "Login",
    });
  },
  data() {
    return {
      username: "",
      password: "",
      captchaCode: "",
      captchaId: "",
      captchaImage: "",
      errors: {
        username: "",
        password: "",
        captchaCode: "",
      },
      error: "",
    };
  },
  async created() {
    await this.loadCaptcha();
  },
  methods: {
    async loadCaptcha() {
      try {
        const captchaResponse = await getCaptchaId();
        this.captchaId = captchaResponse.data.captcha_id;

        const captchaImageBlob = await getCaptchaImage(this.captchaId);
        this.captchaImage = URL.createObjectURL(captchaImageBlob);
      } catch (error) {
        this.error = "Failed to load Captcha";
      }
    },
    async login() {
      this.clearErrors();

      try {
        // Validate form data using schema
        await loginSchema.validate(
          {
            username: this.username,
            password: this.password,
            captchaCode: this.captchaCode,
          },
          { abortEarly: false }
        );

        // Nếu validate thành công, tiếp tục đăng nhập
        try {
          const response = await loginUser(
            this.username,
            this.password,
            this.captchaId,
            this.captchaCode
          );

          if (response.success) {
            // Lưu thông tin token vào localStorage
            localStorage.setItem("access_token", response.data.access_token);
            EventBus.emit("loginSuccess", response.data);
            this.$router.push("/");
          } else {
            this.handleServerErrors(response.errorDetail);
            await this.loadCaptcha(); // Tải lại captcha khi có lỗi
            this.captchaCode = ""; // Reset mã captcha
          }
        } catch (loginError) {
          this.error =
            loginError.message || "An error occurred, please try again later";
          await this.loadCaptcha();
          this.captchaCode = "";
        }
      } catch (validationError) {
        validationError.inner.forEach((err) => {
          this.errors[err.path] = err.message;
        });
      }
    },
    handleServerErrors(errorDetail) {
      if (errorDetail.includes("captcha")) {
        this.errors.captchaCode = errorDetail;
      } else if (errorDetail.includes("username")) {
        this.errors.username = errorDetail;
      } else if (errorDetail.includes("password")) {
        this.errors.password = errorDetail;
      } else {
        this.error = errorDetail;
      }
    },
    clearErrors() {
      this.errors = {
        username: "",
        password: "",
        captchaCode: "",
      };
    },
  },
};
</script>

<style scoped>
/* Your CSS styling here */
</style>
